import { GetServerSideProps, NextPage } from "next";
import { API_CACHE_MAXAGE, API_CACHE_STALE_WHILE_REVALIDATE } from "../../configs";
import { Charts, ChartArt } from "../../chart.constants";
import { ChartRelease, ChartDropdownItem } from "../../chart.models";
import { getChartReleaseAndDates } from "../../services/server/page-data.service";
import ChartPage from "../../components/chart-page.component";
import { handleRouterQueries } from "../../services/client/url.service";
import { useRouter } from "next/router";
import { useEffect } from "react";

type PageProps = {
	chartRelease?: ChartRelease
	allChartReleaseDates: Array<ChartDropdownItem>
}

const CHART = Charts.TopThumbHundred;

const TopThumbHundredChart: NextPage<PageProps> = ({chartRelease, allChartReleaseDates}) => {
	const router = useRouter();
	useEffect(() => {
		let isComponentMounted = true;
		if (chartRelease && isComponentMounted) {
			const query = router.query;
			handleRouterQueries(CHART.path, chartRelease, query);
		}
		return () => { isComponentMounted = false; };
	}, [chartRelease]);

	return <ChartPage
		chartRelease={chartRelease}
		name={CHART.name}
		metaTitle="Pandora's Top Thumb Hundred Chart"
		description={"The Pandora Top Thumb Hundred Chart shows which releases in the last 30 days get the most thumbs up."}
		art={ChartArt.TopThumbHundred}
		chartID={CHART.id}
		weekOfText={"Weekend of "}
		allChartReleases={allChartReleaseDates}
	/>;
};

export const getServerSideProps: GetServerSideProps<PageProps> = async ({res, ...context}) => {
	res.setHeader(
  	"Cache-Control",
		`public, s-maxage=${API_CACHE_MAXAGE}, stale-while-revalidate=${API_CACHE_STALE_WHILE_REVALIDATE}`
	);

	const chartReleaseAndDates = await getChartReleaseAndDates(context, CHART.id);
	const chartRelease = chartReleaseAndDates.props.chartRelease;
	const allChartReleaseDates = chartReleaseAndDates.props.allChartReleaseDates;

	const props: PageProps = { chartRelease, allChartReleaseDates };
	return { props };
};

export default TopThumbHundredChart;